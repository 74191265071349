










































































import moment from 'moment'
import padStart from 'lodash/padStart'
import { Component, Vue, Ref } from 'vue-property-decorator'
import ListView from '@/components/ListView.vue'
import StateSelect from '@/components/Reservation/StateSelect.vue'
import ShopAutocomplete from '@/components/ShopAutocomplete.vue'
import DateSelect from '@/components/DateSelect.vue'
import SelectNullBoolean from '@/components/SelectNullBoolean.vue'
import CSVExportBtn from '@/components/CSVExport.vue'
import CustomerAutocomplete from '@/components/CustomerAutocomplete.vue'

import { Dictionary } from 'vue-router/types/router'
import { Shop } from '@/types/shop'
import { Timecard } from '@/types'

@Component({
  components: {
    ListView,
    StateSelect,
    ShopAutocomplete,
    DateSelect,
    SelectNullBoolean,
    CSVExportBtn,
    CustomerAutocomplete,
  },
})
export default class TimeCardListView extends Vue {
  headers = [
    { text: '日付', value: 'date', width: 100 },
    { text: '店舗コード', value: 'shopCode', width: 100 },
    { text: '店舗', value: 'shopName', width: 200 },
    { text: 'スタッフコード', value: 'staffCode', width: 100 },
    { text: 'スタッフ', value: 'staffName', width: 200 },
    { text: 'Start', value: 'startTime', width: 100 },
    { text: 'End', value: 'endTime', width: 100 },
    { text: '登録日時', value: 'createdAt', width: 150 },
    { text: '更新日時', value: 'updatedAt', width: 150 },
  ]
  filterDefinition = {
    // search: { type: String, default: null },
    shop: { type: String, default: null },
    dateFrom: { type: String, default: null },
    dateTo: { type: String, default: null },
    staff: { type: String, default: null },
  }
  filter: null | Dictionary<unknown> = null
  shops: Shop[] = []
  restNbCols = 0

  get totalCount() {
    return this.$api.timecards().count
  }

  get csvHeaders() {
    let restHeaders = []
    let nb = this.restNbCols
    for (let idx = 0; idx < nb; ++idx) {
      restHeaders.push({
        key: (item: any) => item.restStarts[idx],
        label: `休憩開始時間${idx + 1}`,
      })
      restHeaders.push({
        key: (item: any) => item.restEnds[idx],
        label: `休憩終了時間${idx + 1}`,
      })
    }
    // @ts-ignore
    return CSVHeaders.concat(restHeaders)
  }
  get csvFetch() {
    return () => this.$api.timecards().list(this.listView.query)
  }

  get staffLoggedIn(): boolean {
    return this.$store.getters.staffLoggedIn
  }

  @Ref() readonly listView!: ListView

  get idToShop() {
    let rval = {} as Record<string, Shop>
    this.shops.forEach(shop => {
      rval[shop.id] = shop
    })
    return rval
  }

  mounted() {
    this.fetchShop()
  }

  updateQuery() {
    if (this.filter !== null) this.listView.updateQuery(this.filter)
  }

  getShopCode(shopId: string) {
    return this.idToShop[shopId]?.code
  }

  getShopName(shopId: string) {
    return this.idToShop[shopId]?.name
  }

  async fetchShop(url?: string) {
    const { results, next } = await (url
      ? this.$api.http.get(url)
      : this.$api.shops().list({ all: true }))
    this.shops = this.shops.concat(results)
    if (next) this.fetchShop(next)
  }

  _filterUniqTimecard(items: Timecard[]) {
    // NOTE: 変な重複データを除外
    let rval: Timecard[] = []
    items.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
    items.forEach(item => {
      if (
        rval.every(
          x => x.startTime !== item.startTime && x.endTime !== item.endTime
        )
      ) {
        rval.push(item)
      }
    })
    return rval
  }

  hasErrorItems(items: Timecard[]) {
    // NOTE: 労働時間の集計を阻害するitemがあるか返す
    // 以下エラーにするケース
    // 1. 終了時間が入っていない
    // 2. 開始と終了に重なりがある
    if (
      items.some(
        item =>
          !item.startTime || !item.endTime || item.startTime >= item.endTime
      )
    )
      return true

    const max = items.length
    for (let idx = 0; idx < max; ++idx) {
      const item1 = items[idx]
      // if (!item1.startTime || !item1.endTime) return true
      for (let idx2 = 0; idx2 < max; ++idx2) {
        const item2 = items[idx2]
        // if (!item2.startTime || !item2.endTime) return true
        if (item1.id === item2.id) break
        const s1 = item1.startTime
        const e1 = item1.endTime as string
        const s2 = item2.startTime
        const e2 = item2.endTime as string

        if (
          (s1 < s2 && s2 < e1) ||
          (s1 < e2 && e2 < e1) ||
          (s2 < s1 && s1 < e2) ||
          (s2 < e1 && e1 < e2) ||
          (s1 === s2 && e1 === e2)
        ) {
          return true
        }
      }
    }
    return false
  }

  transformCsvItems(items: Timecard[]) {
    this.restNbCols = 0
    const shopDateStaff2Timecard = {} as Record<string, Timecard[]>
    items.forEach(item => {
      const { shop, date, staff } = item
      const key = shop + date + staff
      let rowItems: Timecard[] = []
      if (key in shopDateStaff2Timecard)
        rowItems = shopDateStaff2Timecard[key] as Timecard[]
      else shopDateStaff2Timecard[key] = rowItems
      rowItems.push(item)
    })
    let rval = [] as any[]
    Object.values(shopDateStaff2Timecard).forEach(rowItems => {
      // rowItems = this._filterUniqTimecard(rowItems)
      let row = {} as any
      let startDt = null as any
      let endDt = null as any
      let ok = true
      let workMinutes = 0
      let restStarts: moment.Moment[] = []
      let restEnds: moment.Moment[] = []
      rowItems.forEach(item => {
        const shop = this.idToShop[item.shop]
        row.date = item.date
        row.staffCode = item.staffCode
        row.staffName = item.staffName
        row.shopCode = shop.code
        row.shopName = shop.name
        let start = moment(`${row.date} ${item.startTime}`)
        restEnds.push(start)
        if (!startDt || startDt.isAfter(start)) startDt = start
        let end = null as null | moment.Moment
        if (item.endTime) {
          end = moment(`${row.date} ${item.endTime}`)
          if (!endDt || endDt.isBefore(end)) endDt = end
          if (end) restStarts.push(end)
        }

        if (ok && start && end) {
          workMinutes += moment.duration(end.diff(start)).asMinutes()
        } else {
          ok = false
        }
      })
      const cmp = (a: moment.Moment, b: moment.Moment) => {
        if (a.isBefore(b)) return -1
        if (a.isSame(b)) return 0
        return 1
      }
      restStarts.sort(cmp)
      restEnds.sort(cmp)
      row.restEnds = restEnds
        .filter(x => x !== startDt)
        .map(x => x.format('HH:mm'))
      row.restStarts = restStarts
        .filter(x => x !== endDt)
        .map(x => x.format('HH:mm'))

      if (this.restNbCols < row.restEnds.length)
        this.restNbCols = row.restEnds.length

      row.startTime = startDt ? startDt.format('HH:mm') : null
      row.endTime = endDt ? endDt.format('HH:mm') : null

      if (startDt && endDt) {
        const m = moment.duration(endDt.diff(startDt)).asMinutes()
        row.totalTime = `${Math.floor(m / 60)}:${padStart(
          String(m % 60),
          2,
          '0'
        )}`
      } else {
        row.totalTime = 'エラー'
      }
      if (ok) {
        const m = workMinutes
        row.workTime = `${Math.floor(m / 60)}:${padStart(
          String(m % 60),
          2,
          '0'
        )}`
      } else {
        row.workTime = 'エラー'
      }
      if (this.hasErrorItems(rowItems)) {
        row.totalTime = 'エラー'
        row.workTime = 'エラー'
      }

      rval.push(row)
    })
    return rval
  }
}
// const extractTime = (value: string | undefined) =>
//   value ? moment(value).format('HH:mm') : ''
// const extractDate = (value: string | undefined) =>
//   value ? moment(value).format('YYYY-MM-DD') : ''
const CSVHeaders = [
  // { key: 'id', label: 'ID' }
  { key: 'date', label: '日付' },
  { key: 'staffCode', label: 'スタッフID' },
  { key: 'staffName', label: 'スタッフ名' },
  { key: 'shopCode', label: '出勤店舗コード' },
  { key: 'shopName', label: '出勤店舗' },
  { key: 'startTime', label: '出勤時間' },
  { key: 'endTime', label: '退勤時間' },
  { key: 'totalTime', label: '拘束時間' },
  { key: 'workTime', label: '実働時間' },
]
